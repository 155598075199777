import importAll from "../common";


const Krowce = {
    id: "Krowce",
    name: {"gb": "Krowce",            
            },
    coverImage: "1.png",
    desc: {
        "gb": "An asynchronous multiplayer endless runner game for PC and Mac, made for Global Game Jam 2024",
    },
    year: "2024",
    fulldesc: {
        "gb": "'Krowce: Po trupach do celu' is an asynchronous multiplayer endless runner game for PC and Mac, made for Global Game Jam 2024. The player plays as a cow and has to run along the field for as long as they can, avoiding various obstacles along the way.<br><br>The obstacles are procedurally generated and additionally can be placed manually by other players after they fail their run, but as not everyone is evil - players can also permanently disable the obstacles by placing helper objects on them. Every placed obstacle or helper has an additional note sticked to it, so after each run the player can read the messages left by other players.<br><br>The game features unique asynchronous multiplayer mode that allows players to compete against each other without the need to be online at the same time.",    
    },
    techstack: "Unity, C#, Python, Django",
    images: importAll(require.context('./Images', false, /\.(png|jpe?g|svg)$/)),
    externalVideos: [
        "https://www.youtube.com/embed/Rqm2SpUjkVA"
    ]
}

  

export default Krowce;