import { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import CarouselItem from 'react-bootstrap/esm/CarouselItem';

import "./ProjectDetails.css";
import ImportedImage from '../ImportedImage/ImportedImage';

const ProjectDetails = ({selectedProject, getLocalizedString, currentLanguage}) => {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
      console.log(selectedProject);
    };

    useEffect(() => {
        setIndex(0);
    }, [selectedProject]);

    return (
        <div className="projectinfo p-4 mt-4">
                <div className="row h-100">
                    <div className="col-md-4 p-2">
                        <h1>{getLocalizedString(selectedProject.name)}</h1>
                        {(selectedProject.year !== undefined) ? <h3>({selectedProject.year})</h3> : null}
                        <div className="text-justify p-4 lh-lg" dangerouslySetInnerHTML={{__html: getLocalizedString(selectedProject.fulldesc)}}/>
                        {
                            (selectedProject.externalResources) ? 
                            <ul className="nav flex-column flex-sm-row justify-content-center mx-auto w-100 p-3">
                                {
                                    selectedProject.externalResources.map((x,i) => {
                                        return(
                                            <li className="nav-item">
                                                <a className="mx-2 btn btn-outline-primary" href={x.href}>{x.title}</a>  
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                            :
                            null
                        }
                        {
                            (selectedProject.techstack !== undefined) ? <div>
                                <h2>{(currentLanguage === "pl") ? "Technologie" : "Technologies"}</h2>
                                <ul className="nav flex-column flex-sm-row justify-content-center nav-pills w-100 mx-auto">
                                    {
                                        selectedProject.techstack.split(',').map((x,i) => {
                                            return ( 
                                            <li key={i} className="nav-item">
                                                <p className="mx-2 nav-link border border-dark rounded-pill">{x}</p>
                                            </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            :
                            null
                        }
                    </div>
                    <div className="col-md-8 p-2">
                        <Carousel fade={true} interval={null} activeIndex={index} onSelect={handleSelect} className="imageList" data-bs-theme="light">
                            {
                                (selectedProject.externalVideos) ?
                                    selectedProject.externalVideos.map((x, i) => {
                                        return (
                                            <CarouselItem key={i}>
                                                {
                                                (index === i) ? 
                                                <iframe className="video-player mx-auto position-absolute" src={x} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                                :
                                                <div></div>
                                                }
                                            </CarouselItem>
                                        )
                                    })
                                :
                                null
                            }
                            {
                                selectedProject.images.map((x, i) => {
                                    return (
                                        <CarouselItem key={i}>
                                            {/* <img src={x.default} alt=" " className="projectImage"></img> */}
                                            <ImportedImage projectId={selectedProject.id} filename={x.substring(2)} innerClassName={"projectImage"} />
                                        </CarouselItem>
                                    )
                                })
                            }

                        </Carousel>
                    </div>
                </div>
            </div>
    );
}

export default ProjectDetails;