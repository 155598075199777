import importAll from "../common";


const SoapTalk = {
    id: "SoapTalk",
    name: {"gb": "SoapTalk",            
            },
    coverImage: "1.jpg",
    desc: {
        "gb": "A casual endless puzzle game for PC and Mac made for Global Game Jam 2025",
    },
    year: "2025",
    fulldesc: {
        "gb": "'SoapTalk' is a casual endless puzzle game for PC and Mac made for Global Game Jam 2025. The player has to put the words spoken by the character in the correct order to make a coherent sentence. The original sentence said by the character is splitted into individual words which fall into the bubble, where they mix and bounce around. The bubble is then blown and flies to another character, who will listen to the sentence. Before the bubble reaches the character, the player needs to put the words in the correct order.<br><br>Some of the words have additional modificators, like gold coin (that awards player with a currency after placing the word correctly), bomb (that explodes and destroys the word when it's timer reaches 0 before the word is put into a container) or a 'jumper' that makes the word jump out of the container and needs to be put again by the player. Each round the player can also use two cards (that are generated randomly each round) that can help the player by automatically putting one word into the correct place, restore player's health points, slow down the bubble or remove all negative word effects.<br><br>The game features a unique mechanic of 'word mixing' that makes the game more challenging and engaging.",
    },
    techstack: "Unity, C#, Blender",
    images: importAll(require.context('./Images', false, /\.(png|jpe?g|svg)$/)),
    externalVideos: [
        "https://www.youtube.com/embed/8c4v9nk33iA"
    ]
}

  

export default SoapTalk;